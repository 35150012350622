import { Injectable } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { BehaviorSubject } from 'rxjs';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class BreadcrumbService {
  private itemsSubject = new BehaviorSubject<MenuItem[]>([]);
  items$ = this.itemsSubject.asObservable();
  lastSubcontractorName: MenuItem[] = [];
  id: string | null = null;

  home: MenuItem = {
    icon: 'fa-regular fa-house'
  };

  constructor(private router: Router, private route: ActivatedRoute) {
    this.updateFromUrl();

    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.updateFromUrl();
      }
    });
  }

  

  insertItem(item: MenuItem) {
    const currentItems = this.itemsSubject.getValue();
    item.label = item.label?.toUpperCase();
    this.itemsSubject.next([...currentItems, item]);
    this.lastSubcontractorName.push(item);
  }

  removeItemsAfter(index: number) {
    const newItems = this.itemsSubject.getValue().slice(0, index + 1);
    this.itemsSubject.next(newItems);
  }

  getHomeItem() {
    return this.home;
  }

  private updateFromUrl() {
    const url = this.router.url;
    const breadcrumbs = this.getBreadcrumbsFromUrl(url);
    this.itemsSubject.next(breadcrumbs);
  }

  private getBreadcrumbsFromUrl(url: string): MenuItem[] {
    if (url.includes('/pages/subcontractor')) {
      return [
        {
          label: 'Registro',
          routerLink: '/pages/subcontractor',
        },
      ]; 
    }

    if (url.includes('/pages/detail/')) {
      const match = url.match(/\/detail\/([^\/]+)/); 
      this.id = match ? match[1] : null;
      const subcontractor = this.lastSubcontractorName?.find((item) => item.id === this.id);
      return [
        {
          label: 'Registro',
          routerLink: '/pages/subcontractor',
        },
        {
          label: subcontractor?.label, 
        },
      ];
    }

    return []; 
  }
}